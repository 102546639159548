<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div class="">
    <BCard class="mt-1">
      <BRow class="justify-content-between align-items-center">
        <BCol lg="6">
          <div class="mb-2">
            <strong class="text-black text-xl">List Pendamping UMKM</strong>
          </div>
        </BCol>
      </BRow>
      <BRow class="justify-content-between align-items-center">
        <BCol lg="4">
          <BInputGroup class="wrapper-search mb-2">
            <template #prepend>
              <BInputGroupText style="border-right: 0">
                <b-img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
                />
              </BInputGroupText>
            </template>
            <BFormInput
              v-model="search"
              placeholder="Cari Nama, Email, No. HP, Kota/Kabupaten"
              style="border-left: 0"
              class="pl-0"
              @input="searchDataList"
            />
          </BInputGroup>
        </BCol>
        <BCol lg="4">
          <div class="mb-2 text-right">
            <BButton
              class="btn-danger btn-sm"
              @click="addPumkm"
            >
              <div class="d-flex align-items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/user-add.svg"
                >
                <span class="ms-2">Tambah PUMKM</span>
              </div>
            </BButton>
          </div>
        </BCol>
      </BRow>
      <BOverlay
        id="infinite-list"
        variant="light"
        class="overflow-auto"
        :show="loading"
        spinner-variant="primary"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          id="table-pumkm"
          responsive
          show-empty
          hover
          empty-text="Tidak ada data untuk ditampilkan."
          :fields="fields"
          :items="datapumkm"
          :per-page="perPage"
          :current-page="currentPage"
          @row-clicked="redirectDetail"
        >
          <template #cell(email)="data">
            <div class="d-flex align-items-center">
              <div class="me-2">
                <b-avatar :src="data.item.photo" />
              </div>
              <div class="min-w-[140px] text-[#222]">
                <span class="d-flex">
                  {{ data.item.full_name }}
                  <img
                    v-if="data.item.id_pumkm !== 0"
                    src="https://storage.googleapis.com/komerce/assets/svg/Badge_PUMKM.svg"
                    alt="badge pumkm"
                    width="16px"
                    class="ml-[5px]"
                  >
                  <img
                    v-if="data.item.id_affiliator !== 0"
                    src="https://storage.googleapis.com/komerce/assets/illustration/badge-affiliate.svg"
                    alt="badge pumkm"
                    width="16px"
                    class="ml-[5px]"
                  >
                </span>
                <span class="text-sm text-[#828282]">{{
                  data.item.email
                }}</span>
              </div>
            </div>
          </template>

          <template #cell(city)="data">
            <span class="text-[#222]">
              {{ data.value.split(',')[0].trim() || '-' }}
            </span>
          </template>

          <template #cell(phone_no)="data">
            <b-button
              size="sm"
              class="p-0 d-flex align-items-center"
              variant="light"
              style="background-color: transparent; border: none"
              @click="redirectToWa(data.item.phone_no)"
            >
              <img
                v-if="data.item.phone_no"
                src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
                class="max-w-[25px] me-2"
              >
              <span class="text-[#222]">{{ data.item.phone_no || '-' }}</span>
            </b-button>
          </template>

          <template #cell(social_media)="data">
            <div class="d-flex align-items-center">
              <b-button
                v-if="data.item.fb !== ''"
                :href="data.item.fb"
                target="_blank"
                size="sm"
                class="p-0"
                variant="light"
                style="background-color: transparent; border: none"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/Facebook-True.svg"
                  class="max-w-[25px]"
                >
              </b-button>
              <b-button
                v-if="data.item.ttk !== ''"
                size="sm"
                class="p-0"
                variant="light"
                style="background-color: transparent; border: none"
                :href="data.item.ttk"
                target="_blank"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/Tiktok-True.svg"
                  class="max-w-[25px]"
                >
              </b-button>
              <b-button
                v-if="data.item.ig !== ''"
                size="sm"
                class="p-0"
                variant="light"
                style="background-color: transparent; border: none"
                :href="data.item.ig"
                target="_blank"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/Instagram-True.svg"
                  class="max-w-[25px]"
                >
              </b-button>
              <b-button
                v-if="data.item.tw !== ''"
                size="sm"
                class="p-0"
                variant="light"
                style="background-color: transparent; border: none"
                :href="data.item.tw"
                target="_blank"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/Tweeter-True.svg"
                  class="max-w-[25px]"
                >
              </b-button>
              <b-button
                v-if="data.item.tg !== ''"
                size="sm"
                class="p-0"
                variant="light"
                style="background-color: transparent; border: none"
                :href="data.item.tg"
                target="_blank"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/Telegram-True.svg"
                  class="max-w-[25px]"
                >
              </b-button>
            </div>
          </template>

          <template #cell(aksi)="data">
            <div class="d-flex align-items-center">
              <b-button
                size="sm"
                class="p-0"
                variant="light"
                style="background-color: transparent; border: none"
                :to="{
                  name: $route.meta.routeEdit,
                  params: {
                    pumkm_id: data.item.id_pumkm,
                    affiliator_id: data.item.id_affiliator,
                  },
                }"
                tag="router-link"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  class="max-w-[25px] mr-[5px]"
                >
              </b-button>
              <b-button
                size="sm"
                class="p-0"
                variant="light"
                style="background-color: transparent; border: none"
                @click="showModalDelete(data.item)"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                  class="max-w-[25px] mr-[5px]"
                >
              </b-button>
              <b-button
                size="sm"
                class="p-0"
                variant="light"
                style="background-color: transparent; border: none"
                @click="showModalCheck(data.item.id_pumkm)"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/monitor.svg"
                  :class="data.item.is_active_sosmed === 1 ? 'max-w-[25px]' : 'max-w-[25px] opacity-30'"
                >
              </b-button>
            </div>
          </template>
        </BTable>
      </BOverlay>
      <BRow class="mt-1">
        <BCol md="6" class="mb-1">
          <div class="bg-light d-flex align-items-center p-50 rounded" style="width: fit-content">
            <span class="text-black mr-1"> List per halaman: </span>
            <BButton
              v-for="page in optionsPage"
              :key="page"
              class="btn-icon"
              size="sm"
              :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
              @click="handlePerPage(page)"
            >
              {{ page }}
            </BButton>
          </div>
        </BCol>
        <BCol md="6">
          <BPagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-pumkm"
            first-number
            last-number
            class="justify-content-end"
            @click="handlePerPage(page)"
          />
        </BCol>
      </BRow>
    </BCard>
    <!-- modal -->
    <ModalDelete
      :data="dataDeletePumkm"
      :get-data-pumkm="getDataPumkm"
    />
    <ModalLink :data="linkPumkm" />
    <ModalSkill :data="dataSkill" />
    <ModalSosmed
      :data="dataShow"
      :facebook="isFacebook"
      :tiktok="isTikTok"
      :instagram="isInstagram"
      :twitter="isTwitter"
      :telegram="isTelegram"
      :get-data-pumkm="getDataPumkm"
    />
  </div>
</template>

<script>
import { affiliateAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { configColumns } from './config'
import ModalDelete from './ModalDelete.vue'
import ModalLink from './ModalLink.vue'
import ModalSkill from './ModalSkill.vue'
import ModalSosmed from './ModalSosmed.vue'

export default {
  components: {
    ModalDelete,
    ModalLink,
    ModalSkill,
    ModalSosmed,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    getDataPumkm: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      search: '',
      fields: configColumns,
      loading: true,
      dataSkill: {},
      linkPumkm: {},
      dataDeletePumkm: {},
      dataEdit: {},
      options: [],
      isLinkPumkm: true,
      numberPhone: '',
      datapumkm: [],
      getName: '',
      getEmail: '',
      getId: 0,
      optionsPage: [20, 50],
      totalPerPage: 20,
      perPage: 20,
      currentPage: 1,
      isShow: false,
      dataShow: {},
      alertError,
      alertSuccess,
      isFacebook: 0,
      isTikTok: 0,
      isInstagram: 0,
      isTwitter: 0,
      isTelegram: 0,
    }
  },
  computed: {
    rows() {
      return this.data.length
    },
  },
  watch: {
    data(newData, oldData) {
      this.loading = false
      this.datapumkm = newData
    },
  },
  async mounted() {
    this.datapumkm = this.data
  },
  methods: {
    searchDataList: _.debounce(function searchData() {
      this.loading = true
      const params = `search=${this.search}`
      const url = `/v1/pumkm/admin/search-pumkm-data?${params}`
      affiliateAxiosIns.get(url).then(res => {
        this.loading = false
        this.datapumkm = res.data.data
      })
    }, 1000),
    showModalDelete(dataDelete) {
      this.dataDeletePumkm = dataDelete
      this.$bvModal.show('modal-delete')
    },
    showDetailSkill(data) {
      this.dataSkill = data
      this.$bvModal.show('modal-skill')
    },
    showModalLink(dataLink) {
      this.linkPumkm = dataLink
      this.$bvModal.show('modal-linkpumkm')
    },
    redirectToWa(dataPhone) {
      window.open(`https://wa.me/62${dataPhone.substring(1)}`, '_blank')
    },
    addPumkm() {
      this.$router.push('/add-pumkm')
    },
    redirectDetail(dataDetail) {
      this.$router.push({
        name: 'detail-pumkm',
        params: { detail: dataDetail },
      })
    },
    handlePerPage(page) {
      this.totalPerPage = page
      this.perPage = page
      this.$emit('handlePage', page)
    },
    showModalCheck(dataCheck) {
      this.loading = true
      const params = dataCheck
      const url = `/v1/pumkm/admin/get-sosmed/${params}`
      affiliateAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.dataShow = data
          this.isFacebook = data.fb_status
          this.isTikTok = data.tk_status
          this.isInstagram = data.ig_status
          this.isTwitter = data.tw_status
          this.isTelegram = data.tg_status
          this.$bvModal.show('modal-sosmed')
        })
        .catch(err => {
          const { response: { data: { message } } } = err
          this.loading = false
          this.$toast_error(message)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './Index.scss';
</style>
